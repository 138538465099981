.cxContainer {
  display: inline-block;
  width: 100%;
  padding: 0 13px;
}

//CX Index Layout

.cxIndexContainer {
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
}

.cxHeading {
    padding: 0 20px;
    font-family: "NeueHelvetica";
    font-size: 16px;
    font-weight: bold;
    color: #54585a;
    border-bottom: 1px solid #e2e3e4;
    margin: 0;
    height: 50px;
    line-height: 56px;
}
.cxbartchart{
  display: inline-flex;
}
.noPadding{
  padding-left: 0;
  padding-right: 0;
}

  .cxbartchartchild{
    padding-top: 7px;
    border-bottom: 1px solid #e2e3e4;
    text-align: right;
  }

.indexContent {
  padding: 20px 0;
  display: flex;
}

.chartLeft {
  padding: 0;
  flex: 0 0 30%;
}

.emptyGauge {
  background: url(../../assets/images/gauge-chart.png) no-repeat;
  height: 120px;
  width: 310px;
}

.indexChartLegend {
  ul {
    flex-wrap: wrap;
    display: flex;
    padding: 0;
    margin: 0;
    li {
      list-style: none;
      margin: 5px 0 0 0;
      flex: 1 0 50%;
      .dash {
        margin-left: 20px;
      }
      .percentValue1 {
        font-size: 18px;
        font-weight: bold;
        color: #4aa707;
        margin-left: 20px;
        
      }
      .percentValue2 {
        font-size: 18px;
        font-weight: bold;
        color: #d01214;
        margin-left: 20px;
        
      }
      .percentValue {
        font-size: 18px;
        font-weight: bold;
        color: #54585a;
        margin-left: 20px;
        
      }
      .percentLabel {
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #54585a;
          display: table-cell;
          vertical-align: middle;
          height: 25px;
      }
      .volatility {
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #54585a;
        margin-left: 20px;
      }
    }
  }
  .arrowUp,
        .arrowDown {
          background: url(../../assets/images/up-arrow.png) no-repeat;
          width: 8px;
          height: 14px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowNoChange {
          background: url(../../assets/images/no-change-arrow.png) no-repeat;
          width: 14px;
          height: 11px;
          display: inline-block;
          margin-left: 5px;
        }
        .arrowDown {
          transform: rotate(180deg);
        }
}
.underDesignChild{
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  // position: absolute;
  // top:-42px
}
.indexDesc {
  flex: 0 0 40%;
  border-left: 1px solid #e2e3e4;
  padding-left: 2% !important;
  display: table !important;
  flex-direction: column;
  justify-content: center;

  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #54585a;
  }
  ul {
    opacity: 0.3;
    padding-left: 0;
    list-style: none;
    li {
      font-size: 14px;
      font-weight: normal;
      color: #54585a;
      margin-bottom: 20px;
      position: relative;
      padding-left: 20px;

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        background-color: #e71316;
        width: 10px;
        height: 10px;
        border-radius: 1rem;
      }
    }
  }
}
.resetPadding {
float: right;
// padding-left: 97px;
bottom: 44px;
}

.btn_success_config {
	background-color: #fff !important;
	color: #000 !important;
	// border-color: #fff !important;
	position: relative;
	text-align: left;
  width: 100%;
  border: 1px solid rgba(0,0,0,.15) !important;
}


.dropdownMenu1 {
	width: 100% !important;
}

.styleArrow::after {
	display: inline-block;
	margin-left: 0;
	vertical-align: 0;
	content: "";
	position: absolute;
	top: 15px;
	right: 9px;
	border-top: 6px solid #fff;
	border-right: 6px solid transparent;
	border-bottom: 0 solid;
	border-left: 6px solid transparent;
}

.styleArrow {
	background-color: red;
	height: 100%;
	width: 30px;
	position: absolute;
	top: 0;
	color: red;
	right: 0;
	border-radius: 0px .25rem .25rem 0;
}

.btn_success_config::after {
	display: none !important;
}

.btn_success_config:focus {
	box-shadow: none !important;
}
.chartPosition{
  top: 60px;
  flex:0 0 45%;
}

.cxScoreTrendBtn1,.cxScoreTrendBtn2{
  // border: 1px solid #ccc;
  padding: 10px 10px 5px 10px;
  display: inline-block;
  color: rgb(102, 102, 102);
  background-color: #eaeaea;
  cursor: pointer;
  font-size: 13px;
  border-radius: 2px;
}
.cxScoreTrendBtn1{
  margin-right: 5px;
}
.activeBtn{
  color: white;
  background-color: #e71316;
}

