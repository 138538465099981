
.chartOuter {
  display: flex;
  padding: 18px 1% 18px 1%;
  height: auto;
  max-height: 400px;;
}

.chartLeft {
  align-self: center;
  width:35%;
}

.chartLegend {
  align-self: center;
  overflow-y: auto;
  max-height: 400px;
  height: auto;
  width:65%;
  margin-left: 2%;
  margin-right: 1%;
  ul {
    margin: 0;
    padding: 0 0 0 2px;
    list-style: none;
    li {
      margin-bottom: 15px;
      display: flex;
      align-items: baseline;
      padding-left: 13px;
      position: relative;
      .listDot {
        width: 11px;
        height: 11px;
        border-radius: 1rem;
        margin-right: 10px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      .labelValue {
        font-size: 14px;
        color: #506883;
        text-align: left;
        //width: 80px;
        width: 36%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .labelName {
        color: #78797c;
        font-size: 14px;
        margin: 0 4px;
        //width: 65px;
        width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .comparePercentage{
          width:17%;
          text-align:center;
        .labelIncrease {
          display:inline-block;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          color: #4aa707;
          width: 70%
        }
        .labelDecrease {
          display:inline-block;
          text-align: center;
          font-size: 13px;
          font-weight: bold;
          color: #d01214;
          width: 70%;
        }
     
      }
     .compareArrow{
         width:10%;

      .labelUpArrow {
        display:inline-block;
        border-bottom: 6px solid #4aa707;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        //margin: 0 0 0 5px;
        margin:0px;
        width: 14%;
      }
      .labelDownArrow {
        display:inline-block;
        border-top: 6px solid #d01214;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        //margin: 0 0 0 5px;
        margin:0px;
        width: 14%;
      }
     }
 
    }
  }
}
