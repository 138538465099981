/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
}

.horizontalDivider{
  background-color: #f2f0f0;//grey;
  //height:10px;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: rgba(194, 118, 119, 0.1);
  color: #535759;
  cursor: pointer;
  padding: 18px;
  padding-left: 14px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover {
  background-color: rgba(194, 118, 119, 0.2);
}

/* Style the accordion content title */
.accordion__title {
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}

.skusLegend {
  text-align: center;
  color: #506883;
  font-size: 14px;
}

.lsgLabel{
  text-align: right;
  padding:5px;
  color:#78797c;
  font-size:12px;
}

/* make accordion disable if no data*/
.makeDisable{
	opacity: 0.5;
	pointer-events: none;
}

/* Styling Barchart*/
.barChartContainer{
  margin-top:20px;
}