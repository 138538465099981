.cxContainer {
  display: inline-block;
  width: 100%;
  padding: 0 5px;//padding: 0 20px; EDEDP-14639
  min-height: calc(100vh - 106px);
}

.leftContent {
  float: left;
  width: 65%;
}

.redesignContainer{
  display:flex;
  width: 98%;
 // background-color:#f2f0f0;
 // border-radius: 14px;
 // box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-bottom: 20px;
}

.scoreGridContainer{
  //width: 98%;
  //display: inline-block;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  padding-left: 0px !important;
  padding-right: 0px !important;
  //margin-left:18px;
  //display:flex;
  flex:0 0 81.6%;
  max-width: 81.6%;
  //margin-right:10px;
}

.casContainer{
  //background: #f0f2f2;
 // border-radius: 14px;
 // box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left:10px;
  flex:0 0 18.33%;
  max-width: 18.33%;
  //margin-right:5px;
  .casCustHeader{
    
      margin-top: 20px;
      background: #fff;
      padding: 0 20px;
      padding-left:12px;
      font-family: "NeueHelvetica";
      font-size: 18px;
      font-weight: 700;
      color: #54585a;
      border-bottom: 1px solid #e2e3e4;
      border-radius: 14px 14px 0 0;
      
      margin: 0;
      padding-bottom: 15px;
      height: 50px; //height:  30px; EDEDP-14873
      line-height: 56px; //line-height:30px; //EDEDP-14873
    
  }
}

.casCustContainer{
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 0.9%;
  flex:0 0 18.33%;
  max-width: 18.33%;
  //margin-right:5px;
  .casCustHeader{
    
      margin-top: 20px;
      background: #fff;
      padding: 0 20px;
      padding-left:12px;
      font-family: "NeueHelvetica";
      font-size: 18px;
      font-weight: 700;
      color: #54585a;
      border-bottom: 1px solid #e2e3e4;
      border-radius: 14px 14px 0 0;
      
      margin: 0;
      padding-bottom: 15px;
      height: 50px; //height:  30px; EDEDP-14873
      line-height: 56px; //line-height:30px; //EDEDP-14873
    
  }
}

.scoreGridHeader{
  margin-top: 20px;
  background: #fff;
  padding: 0 20px;
  font-family: "NeueHelvetica";
  font-size: 18px;
  font-weight: 700;
  color: #54585a;
  border-bottom: 1px solid #e2e3e4;
  border-radius: 14px 14px 0 0;
  
  margin: 0;
  padding-bottom: 15px;
  height: 50px; //height:  30px; EDEDP-14873
  line-height: 56px; //line-height:30px; //EDEDP-14873
}

.scoreGrid {
  background: #fff;
  width: 99%;
 // margin-top:20px;
  display: flex;
  margin-bottom: 0px;
  //margin-bottom: 10px;
  padding-left:0px;
  padding-right:0px;
  border-radius: 0 0 14px 14px;

  .cxScoreContainer{
   //width: 19%;
   width: 22%;
    //margin: auto;
    //margin-top: 0px;
    margin-top: 18px;
    //margin-right:9px;
    .cardContainer{
      margin-right: 0px;
    }
  }
  .kpiContainer{
    //width: 62%;
    width: 77%;
    margin-left: 1px;
    //margin-left: 21px;
    margin-top: 18px;
  }
}
.flexContainer{
  display: flex;
}

.layoutBx {
  background: #fff;// #f2f0f0;
  border-radius: 14px;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.04);// EDEDP-14873
  //min-height: 165px;//EDEDP-14639
  //max-height: 200px;
  margin-bottom: 13px;
  //float: left; //commented by damodar
  width: calc(100% - 10px) !important;
  padding-left:2px;
  padding-right:0px;
}
.cxHeading {
  //padding: 0 20px;
  padding: 20px 15px 20px 14px;
  //padding: 3% 2.5% 3% 2.25%;
  font-family: "NeueHelvetica";
  font-size: 18px;
  font-weight: 700;
  color: #54585a;
  border-bottom: 1px solid #e2e3e4;
  margin: 0;
  height:60px; //height:  30px; EDEDP-14873
  //line-height: 56px; //line-height:30px; //EDEDP-14873
}
.underDesign {
  height: 190px;
  width: 100%;
  font-family: NeueHelvetica;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #54585a;
  padding: 10%;
}
.nbaContainer {
  display: grid;
  grid-gap: 38px;
  //grid-gap: 35px;//damodhar
  //row-gap:5%;
  //column-gap: 5%;
  grid-template-columns: auto auto auto;
  padding: 15px 10px;
  //padding-left:15px;
  //padding-left: 5px;
  //padding-right:5px;

  //border:#e2e3e4;
}

.griditem {
  border: 1px solid #f2f0f0;
	padding: 20px 15px;
	font-size: 14px;
	text-align: center;
	border-radius: 14px;
	cursor: pointer;
  //width:100%;
  //min-width: 220px;
  //max-width: 230px;
  //min-width:170px;//newly added
}
/*.griditem {
  border: 1px solid #f2f0f0;
	padding: 5px;
  padding-top:7px;
	font-size: 12px;
	text-align: center;
	border-radius: 14px;
	cursor: pointer;
  min-width:170px;
  //line-height:15px;
}*/
.recommended {
  color: #fff;
  background-color: #e71316;
}
.otherRcmds{
  border: 1px solid #e71316;
}
.noRcmds{
  // border: 0.25px solid #f2f0f0;// #78797c;//storm grey color code
  border: 1px solid rgba(120, 121, 124, 0.6);
}
.groupTopSection {
  padding: 0 0 20px;
  display: flex;
}

.innerLayout {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
  box-sizing: border-box;
  border-right: 1px solid #e2e3e4;
  &:last-child {
    border-right: 0 none;
  }
}

.bodyTitle {
  color: #78797c;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}

.bodyValue {
  color: #54585a;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.nobgColor{
  background-color: #f2f0f0;
}
.bgColor{
  background-color: #f0f2f2; //#78797c;
}
.rightContent {
  padding: 10px !important;
  padding-left: 5px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  .groupView {
    .groupViewHeader {
      border-radius: 14px 14px 0 0;
      background: #e71316;
      padding: 20px 20px 15px;
      color: #fff;
    }
    .groupViewBody {
      background: #fff;
      border-radius: 0 0 14px 14px;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  }
}