.container {
  display: inline-block;
  width: 100%;
  min-height: calc(100vh - 106px);
  .radioWrapper{
    padding: 15px 10px 15px 10px;
    display: inline-block;
    margin-bottom: 20px;
    margin-left:20px;
    width: 265px;
    height: 60px;
    border: 5px solid red;
    border-radius:15px;
    .radioLabel{
      width:115px;
      display: inline-block;
      position: absolute;
      top:20px;
    }
    .radioForm{
      display: inline-block;
      position: absolute;
      left:150px;
    }
    .formRadioInline{
      margin-right:15px;
    }
    
   :global(.form-check-label){
        margin-bottom: -4px;  
    }
  }
  .dummyWrapper{
    padding: 0 20px;
    display: inline-block;
    margin-bottom: 10px;
    width: 17%;
  }
}
.productsContainer {
  padding: 0 20px;
  display: flex;
  margin-bottom: 10px;
}

.scrollStartParent{
  display:grid;
  position: relative;
  height: 150px;
  width: 10px;
  .rightArrow{
    position: absolute;
    top: calc(50% - 25px);
    right: -10px;
    top: 72px;
    background-color: rgba(0, 0, 0, .2);
    //background-color: #e71316;
    //color: #fff;
    color: #333;
    border-radius: 10px;
    //z-index:5;
    margin-right:20px;
  }

  .rightArrow:hover{
    background-color: #78797c;
    color:#fff;
  }

  .rightSRArrow{
    position: absolute;
    top: calc(50% - 25px);
    right: -10px;
    top: 74px;
    background-color: rgba(0, 0, 0, .2);
    //background-color: #e71316;
    //color: #fff;
    color: #333;
    border-radius: 10px;
    //z-index:5;
    margin-right:20px;
  }

  .rightSRArrow:hover{
    background-color: #78797c;
    color:#fff;
  }

  .rightPDArrow{
    position: absolute;
    top: calc(50% - 25px);
    right: 0px;
    top: 82px;
    background-color: rgba(0, 0, 0, .2);
    //background-color: #e71316;
    //color: #fff;
    color: #333;
    border-radius: 10px;
    //z-index:5;
    margin-right:20px;
  }

  .rightPDArrow:hover{
    background-color: #78797c;
    color:#fff;
  }
}

.scrollEndParent{
    display:grid;
    position: relative;
    height: 150px;
    //width: 10px;
    
  .leftArrow{
   position: absolute;
   top: calc(50% - 25px);
   left: -3px;
   top: 73px;
   background-color: rgba(0, 0, 0, .2);
   color: #333;
   //background-color: #e71316;
   //color: #fff;
   border-radius: 10px;
   z-index:5;
   margin-right:20px;
  }
  .leftArrow:hover{
    background-color: #78797c;
    color:#fff;
  }
  .leftSRArrow{
    position: absolute;
    top: calc(50% - 25px);
    left: -3px;
    top: 74px;
    background-color: rgba(0, 0, 0, .2);
    color: #333;
    //background-color: #e71316;
    //color: #fff;
    border-radius: 10px;
    z-index:5;
    margin-right:20px;
   }
   .leftSRArrow:hover{
     background-color: #78797c;
     color:#fff;
   }

   .leftPDArrow{
    position: absolute;
    top: calc(50% - 25px);
    left: 0px;//10px;
    top: 83px;
    background-color: rgba(0, 0, 0, .2);
    color: #333;
    //background-color: #e71316;
    //color: #fff;
    border-radius: 10px;
    z-index:5;
    margin-right:20px;
   }
   .leftPDArrow:hover{
     background-color: #78797c;
     color:#fff;
    //background-color: #e71316;
    //color: #fff;
   }
}

 .buttonStyle{
    box-sizing: border-box;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    font-size: 1.6em;
    background-color: rgba(103, 58, 183, 0.1);
    color: #333;
    box-shadow: 0 0 2px 0px #333;
    border-radius: 50%;
    border: none;
    padding-left: 20px;
    margin-left: 1px;
    width: 50px;
    height: 50px;
    min-width: 50px;
    line-height: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    cursor: pointer;
    outline: none;
 }


.customerLayoutBx {
  float: left;
  width: 100%;
}
.groupSessionBx {
  padding: 0 0 20px 20px;
  width: 27%;
  float: left;
  .cardContainer {
    position: relative;
    margin-top: 10px;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      right: -12px;
      top: calc(50% - 7px);
      border-left: 14px solid #fff;
      border-top: 14px solid transparent;
      border-bottom: 14px solid transparent;
    }
  }
}

.outerGrid {
  border-radius: 14px;
  border: solid 1px #e2e3e4;
  padding: 10px 10px 10px 0;;
  .cardContainer {
    margin: 0 10px;
    cursor: pointer;
  }
}

.tilePaddingbottom{
  padding-bottom: 10px;
}

.tilePaddingtop{
  padding-top: 10px;
}

.productBox {
  width: 100%;
}

.underDesign {
  height: 190px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
}
.underDesignText {
  font-family: NeueHelvetica;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #54585a;
  padding: 5%;
}
.favLayoutBx {
  background-color: rgba(0, 0, 0, 0.1);
    float: left;
    width: 100%;
    padding: 20px;
}
.ackTitle{
  // font-family: "NeueHelveticaBd";
  font-family: NeueHelvetica;
  color: #54585a;
}
.spinAlign{
  margin-right: 20px;
  margin-top: 10px;
}
.customBtn {
	background-color: #e71316 !important;
	border: 1px solid #e71316 !important;
}